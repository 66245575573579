exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-usecases-js": () => import("./../../../src/pages/usecases.js" /* webpackChunkName: "component---src-pages-usecases-js" */),
  "component---src-templates-custom-page-js": () => import("./../../../src/templates/customPage.js" /* webpackChunkName: "component---src-templates-custom-page-js" */),
  "component---src-templates-service-post-js": () => import("./../../../src/templates/servicePost.js" /* webpackChunkName: "component---src-templates-service-post-js" */)
}

